import axios from "axios";
import { tokenConfig } from "./auth";
import { createMessage, returnErrors } from "./messages";
import config from "../components/common/config";


import { GET_SERVICES, ADD_SERVICE, GET_ERRORS } from './types';

const apiUrl = `${config.api_root_url}/restapi/services/`;

// GET SERVICES
export const getServices = () => (dispatch, getState) => {    
    axios.get(apiUrl)
        .then(res => {
            dispatch({
                type: GET_SERVICES,
                payload: res.data
            });
        })
        .catch(err => HTMLFormControlsCollection.log(err));
}

// ADD SERVICE
export const addService = (service) => (dispatch, getState) => {
    axios.post(apiUrl, service, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({
                serviceAdded: "Услуга добавлена"
            }));
            dispatch({
                type: ADD_SERVICE,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status )));
}