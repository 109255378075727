import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getServices } from '../../actions/services';


export class Services extends Component {
    static propTypes = {
        services: PropTypes.array.isRequired,
        auth: PropTypes.object.isRequired,
        getServices: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.props.getServices();        
    }

    render() {
        const { isAuthenticated, user } = this.props.auth;

        return (
            <Fragment>
                <h3>Услуги и цены</h3>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            { isAuthenticated && user.is_manager ? <th>id</th> : ""}
                            <th>Услуга</th>
                            <th>Цена в рублях</th>
                            <th>Единица измерения</th>
                            <th>Примечание</th>
                            { isAuthenticated && user.is_manager ? <th/> : ""}
                        </tr>
                    </thead>
                    <tbody>
                        { this.props.services.map(service => (
                            <tr key={service.id}>
                                { isAuthenticated && user.is_manager ? <td>{service.id}</td> : ""}
                                <td>{service.service_name}</td>
                                <td>{service.price}</td>
                                <td>{service.uom}</td>
                                <td>{service.description}</td>
                                { isAuthenticated && user.is_manager ? <td><button className="btn btn-danger btn-sm">Delete</button></td> : ""}                                
                            </tr>
                        )) }
                    </tbody>
                </table>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    services: state.services.services,
    auth: state.auth
})

export default connect(mapStateToProps, {getServices})(Services);
