import axios from "axios";
import { returnErrors } from "./messages";

import config from "../components/common/config";

import { USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS } from "./types";

// Check token and load user
export const loadUser = () => (dispatch, getState) => {
    const apiUrl = `${config.api_root_url}/restapi/auth/user/`;

    dispatch({ type: USER_LOADING});

    axios.get(apiUrl, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_LOADED,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: AUTH_ERROR
            })
        });
}

// Login user
export const login = (username, password) => dispatch => {
    const apiUrl = `${config.api_root_url}/restapi/auth/dologinn/`;

    // Headers
    const headersConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // Request body
    const body = JSON.stringify({ username, password });

    axios.post(apiUrl, body, headersConfig)
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: LOGIN_FAIL
            })
        });
}

// Logout user
export const logout = () => (dispatch, getState) => {
    const apiUrl = `${config.api_root_url}/restapi/auth/logout/`;

    axios.post(apiUrl,null, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LOGOUT_SUCCESS
            });
        }).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
}

//Setup config with token
export const tokenConfig = getState => {
    // Get token from state
    const token = getState().auth.token;

    // Headers
    const headersConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // If token, add to headers config
    if (token) {
        headersConfig.headers['Authorization'] = `Token ${token}`;
    }

    return headersConfig;
}