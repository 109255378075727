export const GET_SERVICES   = 'GET_SERVICES';
export const ADD_SERVICE    = 'ADD_SERVICE';
export const GET_ERRORS     = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ORDERS     = 'GET_ORDERS';
export const ADD_ORDER      = 'ADD_ORDER';
export const ORDERS_LOADED  = 'ORDERS_LOADED';
export const ORDERS_LOADING = 'ORDERS_LOADING';
export const USER_LOADING   = 'USER_LOADING';
export const USER_LOADED    = 'USER_LOADED';
export const AUTH_ERROR     = 'AUTH_ERROR';
export const LOGIN_SUCCESS  = 'LOGIN_SUCCESS';
export const LOGIN_FAIL     = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS  = 'REGISTER_SUCCESS';
export const REGISTER_FAIL     = 'REGISTER_FAIL';
