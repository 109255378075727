import { combineReducers } from "redux";
import services from './services';
import errors from './errors'
import messages from "./messages";
import orders from "./orders";
import auth from "./auth";

export default combineReducers({
    services,
    orders,
    errors,
    messages,
    auth
})