import React, { Component, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect, Connect } from 'react-redux'

import Orders from './Orders';
import Guest from './Guest';

export class IndexPage extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
    }

    render() {
        const { isAuthenticated, user } = this.props.auth;
        
        return (
            <Container>
                 { isAuthenticated && user.is_manager ? <Orders /> : <Guest />}
            </Container>
        );        
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(IndexPage);
