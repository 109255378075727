import React, { Component } from 'react'
import { Button, Collapse } from 'react-bootstrap'

export class OrdersFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false
        };
    }

    render() {
        return (
            <div className="row" id="main-row">
                <div className="col" id="left_col">
                    <div className="row">
                        <div className="col-lg-1">
                        <Button className="btn form-control btn-sm"
                            onClick = {() => {
                                this.setState( {open: !this.state.open});
                                console.log("OnClick fired")
                            } }
                            aria-controls="collapseFilter"
                            aria-expanded={this.state.open}
                        >
                        Фильтр
                        </Button>
                        </div>
                        <div className="col-lg-1">
                            <Button variant="outline-secondary" size='sm' id="btn-1">Назначить</Button>
                        </div>
                        <div className="col-lg-1">
                            <Button variant="outline-secondary" size='sm' id="btn-2">Отменить</Button>
                        </div>
                        <div className="col-lg-1">
                            <Button variant="outline-secondary" size='sm' id="btn-3" onClick="OnBulkClose();">Закрыть</Button>
                        </div>
                        <div className="col-lg-1">
                            <Button variant="outline-secondary" size='sm' id="btn-4" onClick="OnBulkReopen();">Переоткрыть</Button>
                        </div>
                    </div>
                    <Collapse in={this.state.open}>
                    <div className="row mt-2" id="collapseFilter">
                        <div className="col-3">
                            <label className="form-check-label" for="filter-id">
                                Номер заказа
                            </label>
                            <input type="text" className="form-control form-control-sm" id="filter-id" />

                            <label className="form-check-label" for="filter-phone">
                                Телефон заказчика
                            </label>
                            <input type="text" className="form-control form-control-sm" id="filter-phone" />

                            <label className="form-check-label" for="filter-client">
                                Имя заказчика
                            </label>
                            <input type="text" className="form-control form-control-sm" id="filter-client" />
                        </div>

                        <div className="col-3">
                            <label className="form-check-label" for="filter-order-date">
                                Дата заказа
                            </label>
                            <input type="date" className="form-control" id="filter-order-date" />

                            <label className="form-check-label" for="filter-desired-date">
                                Дата исполнения
                            </label>

                            <input type="date" className="form-control" id="filter-desired-date" />
                        </div>

                        <div className="col-3">
                            <label className="form-check-label" for="filter-workman">
                                Мастер
                            </label>
                            <select className="form-select form-select-sm" aria-label=".form-select-sm workman"
                                id="filter-workman">
                                <option selected>-</option>
                                <option value="1">Василий Пупкин</option>
                                <option value="2">Иван Петров</option>
                                <option value="3">Петр Сидоров</option>
                            </select>

                            <label className="form-check-label" for="filter-status">
                                Статус
                            </label>
                            <select className="form-select form-select-sm" aria-label=".form-select-sm status"
                                id="filter-status">
                                <option value="Новый">Новый</option>
                                <option value="Исполняется">Исполняется</option>
                                <option value="Исполнен">Исполнен</option>
                                <option value="Отменен">Отменен</option>
                            </select>
                        </div>
                        <div className="col-3">
                            <div className='d-grid gap-7"'>
                                {/* <div className="col ml-3 mr-3"> */}
                                    <Button variant="outline-primary" size='sm' id="btn-4" onClick="ComposeFilterList();">Применить</Button>
                                {/* </div> */}

                                {/* <div className="col ml-3 mr-3"> */}
                                    <Button variant="outline-secondary" className="mt-3" size='sm' id="btn-4">Сбросить</Button>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    </Collapse>
                </div>
            </div>
        )
    }
}

export default OrdersFilter
