import { ADD_ORDER, ORDERS_LOADED, ORDERS_LOADING } from "../actions/types.js";

const initialState = {
    isLoading: false,
    orders: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ORDERS_LOADING:
            return {
                ...state,
                isLoading: true,
                orders: []
            }
        case ORDERS_LOADED:
            return {
                ...state,
                isLoading: false,
                orders: action.payload
            }
        case ADD_ORDER:
            return {
                ...state,
                orders: [...state.orders, action.payload]
            }
        default:
            return state;
    }
}