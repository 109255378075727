import React, { Component } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';

export class SpinnerComponent extends Component {
    render() {
        return (
            <Row className="justify-content-md-center">
                <Col xs={2} className="align-self-center">
                    <Spinner animation="border" role="status" variant="primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        )
    }
}

export default SpinnerComponent;
