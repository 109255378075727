import { GET_SERVICES } from "../actions/types.js";
import { ADD_SERVICE } from "../actions/types.js";

const initialState = {
    services: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SERVICES:
            return {
                ...state,
                services: action.payload
            }
        case ADD_SERVICE:
            return {
                ...state,
                services: [...state.services, action.payload]
            }
        default:
            return state;
    }
}