import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { connect, Connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';


export class Header extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    } 

    render() {
        const { isAuthenticated, user } = this.props.auth;

        const authLink = (
            <Nav>
            <div className='navbar-text mr-3'>
                <strong>
                    { user ? `${user.first_name} ${user.last_name}` : ""}
                </strong>
            </div>
            <button onClick={this.props.logout} className='nav-link btn btn-info btn-sm text-light'>Выйти</button>
            </Nav>
            /* <Nav.Link href="/logout">Выйти</Nav.Link> */
        )

        const guestLink = (
            <Nav>
                <Nav.Link href="/login">Войти</Nav.Link>
            </Nav>
        )

        return (
            <Navbar bg="light" expand="md">
                <Container>                    
                    <Navbar.Brand href="/">Рога и Копыта</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            { isAuthenticated && user.is_workman ? <Nav.Link href="/my_orders">Мои заказы</Nav.Link> : ""}
                            { isAuthenticated && user.is_staff ? <Nav.Link href="/orders">Заказы</Nav.Link> : ""}
                            { isAuthenticated && user.is_workman ? <Nav.Link href="/pickup_order">Взять заказ</Nav.Link> : ""}                            
                            { isAuthenticated && user.is_manager ? <Nav.Link href="/new_order">Новый заказ</Nav.Link> : ""}
                            { isAuthenticated && user.is_manager ? <Nav.Link href="/staff">Персонал</Nav.Link> : ""}
                            { isAuthenticated && user.is_staff ? <Nav.Link href="/price_list">Услуги</Nav.Link> : ""}
                            { isAuthenticated && user.is_manager ? <Nav.Link href="/workmen">Мастера</Nav.Link> : ""}
                        </Nav>
                        { isAuthenticated ? authLink : guestLink }                        
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {logout})(Header);
