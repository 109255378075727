import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

export class Footer extends Component {
    render() {
        return (
            <Container className="container-footer">
                <footer className="footer border-top">
                    <div className="row">
                        <div className="col-md-6">
                            <address>
                                <strong>Рога и Копыта</strong><br />
                                Мытищи, ул. Космонавтов, 12<br />
                                <abbr title="Не звоните по этому телефону">Телефон: <a className="link" href="tel:(123) 2-12-85-06">(123)
                                    2-12-85-06</a></abbr>
                            </address>
                            <address>
                                <strong>E-mail</strong><br />
                                <abbr
                                    title="На этот адрес можно писать, если Вы заинтеревовались этой системой или Вам нужна аналогичная">
                                    <a className="link" href="mailto:softdev@ask-softdev.ru">softdev@ask-softdev.ru</a>
                                </abbr>
                            </address>
                        </div>
                        <div className="col-md-6 footer-copyrgt">
                            &copy; Калаленский Андрей, 2021
                        </div>
                    </div>
                </footer>
            </Container>
        )
    }
}

export default Footer
