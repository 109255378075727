import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addService } from '../../actions/services';


export class Service extends Component {
    state = {
        service_name: '',
        uom: '',
        description: '',
        price: 0
    }

    static propTypes = {
        addService: PropTypes.func.isRequired,
    };

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    onSubmit = (e) => {
        e.preventDefault();
        const { service_name, uom, description, price } = this.state;
        const service = { service_name, uom, description, price };
        this.props.addService(service);
        this.setState({
            service_name: '',
            uom: '',
            description: '',
            price: 0
        });
    };


    render() {
        const { service_name, uom, description, price } = this.state;
        return (
            <div className="card card-body mt-4 mb-4">
                <h3>Добавить новую услугу</h3>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label>Наименование</label>
                        <input className='form-control'
                            type="text"
                            name="service_name"
                            onChange={this.onChange}
                            value={service_name}
                        />
                    </div>
                    <div className="form-group">
                        <label>Единица измерения</label>
                        <input className='form-control'
                            type="text"
                            name="uom"
                            onChange={this.onChange}
                            value={uom}
                        />
                    </div>
                    <div className="form-group">
                        <label>Описание</label>
                        <input className='form-control'
                            type="text"
                            name="description"
                            onChange={this.onChange}
                            value={description}
                        />
                    </div>
                    <div className="form-group">
                        <label>Цена</label>
                        <input className='form-control'
                            type="number"
                            name="price"
                            onChange={this.onChange}
                            value={price}
                        />
                    </div>
                    <div className="form-group mt-4">
                        <button type="submit" className="btn btn-primary">
                            Добавить
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default connect(null, {addService})(Service);
