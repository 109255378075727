import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';
import { getOrders } from '../../actions/orders';
import SpinnerComponent from '../layout/SpinnerComponent';

export class OrdersList extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        orders: PropTypes.array.isRequired,
        getOrders: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.props.getOrders();
    }

    render() {
        if (this.props.isLoading) {
            return (
                <SpinnerComponent />
            );
        }
        else {
            return (
                <Fragment>
                    <div className='row'>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Номер</th>
                                    <th>Заказчик</th>
                                    <th>Телефон</th>
                                    <th>Работы</th>
                                    <th>Дата заказа</th>
                                    <th>Исполнение</th>
                                    <th>Мастер</th>
                                    <th>Статус</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.orders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.client_name}</td>
                                        <td><a href={`tel:${order.client_phone}`}>{order.client_phone}</a></td>
                                        <td>"To be done later"</td>
                                        <td>{order.datetime_created}</td>
                                        <td>{order.datetime_desired}</td>
                                        <td>{order.service_man}</td>
                                        <td>{order.status}</td>
                                        <td><button className="btn btn-danger btn-sm">Удалить</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='row mt=2'>
                        <Pagination>
                            <Pagination.First />
                            <Pagination.Prev />
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Ellipsis />

                            <Pagination.Item>{10}</Pagination.Item>
                            <Pagination.Item>{11}</Pagination.Item>
                            <Pagination.Item active>{12}</Pagination.Item>
                            <Pagination.Item>{13}</Pagination.Item>
                            <Pagination.Item disabled>{14}</Pagination.Item>

                            <Pagination.Ellipsis />
                            <Pagination.Item>{20}</Pagination.Item>
                            <Pagination.Next />
                            <Pagination.Last />
                        </Pagination>
                    </div>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.orders.isLoading,
    orders: state.orders.orders,
})

export default connect(mapStateToProps, { getOrders })(OrdersList);
