import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Services from './Services';

export class Guest extends Component {
    render() {
        return (
            <Container>
                <Row id="main-row-1" className="align-items-md-stretch">
                    <Col md={6} className="d-none d-md-block">
                        <div className="h-100 p-5 mt-3 bg-light rounded-3">
                            <h2>Добро пожаловать в наш сервисный центр</h2>
                            <p>Если у Вас что-то сломалось, или у Вас некому погулять с собакой, или Вам нужно срочно сделать
                                какой-либо мелкий ремонт в доме - обрвщаайтесь к нам.</p>
                            <hr />
                            <p>Это демонстрационный сайт вымышленной компании, работающей по принципу:</p>
                            <ul>
                                <li>заказчик делает заказ онлайн или через диспетчера по телефону</li>
                                <li>диспетчер на телефоне может принять заказ и назначить любой заказ исполнителю</li>
                                <li>исполнители (мастера) получают заказы онлайн</li>
                                <li>мастер может выбрать себе заказ, если он не распределен другому мастеру</li>
                            </ul>
                            <p>Вы можете попробовать использовать сайт от имени диспетчера (менеджера) или мастера,
                                исползуя логины, <a href="#logins">приведенные ниже</a></p>

                        </div>
                    </Col>

                    <Col className="col-md-6">
                        <div className="h-100 p-5 rounded-3">
                            <div className="main-page-control d-none d-md-block">
                                <Button className="btn btn-outline-light">Заказать
                                    услугу</Button>
                            </div>
                            <div className="d-md-none">
                                <Button className="btn btn-outline-light">Заказать
                                    услугу</Button>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col>
                        <Services />
                    </Col>
                </Row>

                <Row id="main-row-3" className="align-items-md-stretch mb-3">
                    <Col md={12} className="d-none d-md-block mt-3">
                        <div id="main-row-2-content-2" className="h-100 p-5 bg-light rounded-3">
                            <p id="logins">Для доступа к служебной части используйте следующие логины:</p>
                            <ul>
                                <li>Менеджер: login - manager password - hello123456</li>
                                <li>Мастер: login - workman password - hello123456</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default Guest;
