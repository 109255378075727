import axios from "axios";
import { createMessage } from "./messages";

import config from "../components/common/config";

import { GET_ORDERS, ADD_ORDER, GET_ERRORS, ORDERS_LOADED, ORDERS_LOADING } from './types';

const apiUrl = `${config.api_root_url}/restapi/orders/`;

// GET ORDERS
export const getOrders = () => (dispatch, getState) => {
    dispatch({ type: ORDERS_LOADING});

    axios.get(apiUrl)
        .then(res => {
            dispatch({
                type: ORDERS_LOADED,
                payload: res.data
            });
        })
        .catch(err => HTMLFormControlsCollection.log(err));
}

// ADD ORDER
export const addOrder = (order) => (dispatch, getState) => {
    axios.post(apiUrl, order)
        .then(res => {
            dispatch(createMessage({
                orderAdded: "Заказ создан"
            }));
            dispatch({
                type: ADD_ORDER,
                payload: res.data
            });
        })
        .catch(err => {
            const errors = {
                msg: err.response.data,
                status: err.response.status
            };
            dispatch({
                type: GET_ERRORS,
                payload: errors
            });
        });
}