import React, { Component, Fragment } from 'react';
import { Container } from 'react-bootstrap';

import Service from '../content/Service';
import Services from '../content/Services';
import Orders from '../content/Orders';

export class Body extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Service />
                    <Services />
                    <Orders />
                </Container>
            </Fragment>
        )
    }
}

export default Body
