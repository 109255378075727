import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { Provider } from 'react-redux';

import { Provider as AlertProvider } from 'react-alert';


import AlertTemplate from 'react-alert-template-basic';

import '../css/App.scss';

import Header from './layout/Header';
import Services from './content/Services';
import Footer from './layout/Footer';
import Body from './layout/Body';
import Alerts from './layout/Alerts';
import Login from './accounts/Login';
import Register from './accounts/Register';
import PrivateRoute from './common/PrivateRoute';
import IndexPage from './content/IndexPage';
import Orders from './content/Orders';


import store from '../store';
import { loadUser } from '../actions/auth';


// Alert Options
const alertOptions = {
  timeout: 3000,
  position: 'top center'
}

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate}
          {...alertOptions}>
          <Router>
            <Fragment>
              <Header />
              <Alerts />
                <Switch>
                  <Route exact path="/" component={IndexPage} />
                  <Route exact path="/all" component={Body} />
                  <Route exact path="/login" component = {Login} />
                  <Route exact path="/orders" component = {Orders} />
                </Switch>
              <Footer />
            </Fragment>
          </Router>
        </AlertProvider>
      </Provider>
    );
  }
}

export default App;
