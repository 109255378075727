import React, { Component, Fragment } from 'react'
import { Tab, Tabs } from 'react-bootstrap'

import OrdersList from './OrdersList'
import OrdersFilter from './OrdersFilter'
import OrdersMap from './OrdersMap'

export class Orders extends Component {
    render() {
        return (
            <Fragment>
                <div className="row">
                    <div className="col">
                        <h3>Заказы</h3>
                        <small><i>Кликните по строке заказа что бы открыть подробности. Кликните по
                            номеру заказа для редактирования.</i></small>
                        <hr />
                    </div>
                </div>
                <OrdersFilter />
                <Tabs defaultActiveKey="orders-list" id="orders-tab" className="mb-3 mt-3">
                    <Tab eventKey="orders-list" title="Список">
                        <OrdersList />
                    </Tab>
                    <Tab eventKey="orders-map" title="Карта">
                        <OrdersMap />
                    </Tab>
                </Tabs>                
            </Fragment>
        )
    }
}

export default Orders
